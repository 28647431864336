export const LOCAL_STORAGE_FEATURE_FLAGS_KEY = 'featureFlagsAtom';

export enum ExacareFeature {
  CRM_ADMIN_HUB = 'VITE_FEATURE_FLAG_CRM_ADMIN_HUB',
  VACCINES = 'VITE_FEATURE_FLAG_VACCINES',
  OOC_PHASE_2 = 'VITE_FEATURE_FLAG_OOC_PHASE_2',
  PHARMACY = 'VITE_FEATURE_FLAG_PHARMACY',
  PHARMACY_V2 = 'VITE_FEATURE_FLAG_PHARMACY_V2',
  CRM_ACTIVITY_LOG = 'VITE_FEATURE_FLAG_CRM_ACTIVITY_LOG',
  ARCHIVED_RESIDENTS = 'VITE_FEATURE_FLAG_ARCHIVED_RESIDENTS',
  MEDICATION_TASK_REGIMENS = 'VITE_FEATURE_FLAG_MEDICATION_TASK_REGIMENS',
  CRM_RESIDENT_CALLS = 'VITE_FEATURE_FLAG_CRM_RESIDENT_CALLS',
  CRM_RESIDENT_TEXTS = 'VITE_FEATURE_FLAG_CRM_RESIDENT_TEXTS',
  VITE_FEATURE_FLAG_INCIDENTS_V2 = 'VITE_FEATURE_FLAG_INCIDENTS_V2',
  DATA_CENTER_TAB = 'VITE_FEATURE_FLAG_DATA_CENTER_TAB',
  SLIDING_SCALE_DOSE = 'VITE_FEATURE_FLAG_SLIDING_SCALE_DOSE',
  BILLING_V2 = 'VITE_FEATURE_FLAG_BILLING_V2',
  ASSESSMENTS_CARE_PLAN_ESIGN = 'VITE_FEATURE_FLAG_ASSESSMENTS_CARE_PLAN_ESIGN',
  INCIDENT_NOTIFICATIONS = 'VITE_FEATURE_FLAG_INCIDENT_NOTIFICATIONS',
  BILLING_ONE_TIME_CHARGES = 'VITE_FEATURE_FLAG_BILLING_ONE_TIME_CHARGES',
  NEW_VITALS_API = 'VITE_FEATURE_FLAG_NEW_VITALS_API',
  SEND_PAYMENT_LINKS = 'VITE_FEATURE_FLAG_SEND_PAYMENT_LINKS',
  TASKS_PAGE_V2 = 'VITE_FEATURE_FLAG_TASKS_PAGE_V2',
  BILLS_NEXT_PREV_BUTTONS = 'VITE_FEATURE_FLAG_BILLS_NEXT_PREV_BUTTONS',
  BILLING_MULTI_PAYMENTS = 'VITE_FEATURE_FLAG_BILLING_MULTI_PAYMENTS',
  BILLING_GENERAL_SETTINGS = 'VITE_FEATURE_FLAG_BILLING_GENERAL_SETTINGS',
  INTERCOM = 'VITE_FEATURE_FLAG_INTERCOM',
  BILLING_STATEMENTS = 'VITE_FEATURE_FLAG_BILLING_STATEMENTS',
  EHR_MEDICATIONS_PATTERNED_RECURRENCE = 'VITE_FEATURE_FLAG_EHR_MEDICATIONS_PATTERNED_RECURRENCE',
  BILLING_DEPOSITS_AND_CREDITS = 'VITE_FEATURE_FLAG_BILLING_DEPOSITS_AND_CREDITS',
  VITE_FEATURE_FLAG_USER_LANDING_PREFERENCES = 'VITE_FEATURE_FLAG_USER_LANDING_PREFERENCES',
  BILLING_PAYMENT_LINK_OPTION = 'VITE_FEATURE_FLAG_BILLING_PAYMENT_LINK_OPTION',
  BILLING_AR_AGING_REPORT = 'VITE_FEATURE_FLAG_BILLING_AR_AGING_REPORT',
  EHR_TASK_EXTENSIONS = 'VITE_FEATURE_FLAG_EHR_TASK_EXTENSIONS',
  CRM_SCREENER = 'VITE_FEATURE_FLAG_CRM_SCREENER',
  CRM_SCREENER_REPORT = 'VITE_FEATURE_FLAG_CRM_SCREENER_REPORT',
  CRM_TASK_REMINDERS = 'VITE_FEATURE_FLAG_CRM_TASK_REMINDERS',
  CRM_SCREENER_LEAD_COMMENTS = 'VITE_FEATURE_FLAG_CRM_SCREENER_LEAD_COMMENTS',
  VITE_FEATURE_FLAG_CRM_SCREENER_SETTINGS_INTEGRATIONS = 'VITE_FEATURE_FLAG_CRM_SCREENER_SETTINGS_INTEGRATIONS',
  CRM_SCREENER_PCC_ADVANCED_INTEGRATION = 'VITE_FEATURE_FLAG_CRM_SCREENER_PCC_ADVANCED_INTEGRATION',
  VITE_FEATURE_FLAG_CRM_SCREENER_RULE_AI_SUGGESTIONS = 'VITE_FEATURE_FLAG_CRM_SCREENER_RULE_AI_SUGGESTIONS',
  CRM_SCREENER_MEDICARE = 'VITE_FEATURE_FLAG_CRM_SCREENER_MEDICARE',
  CRM_SCREENER_LEAD_S_OFFENDER_CHECK = 'VITE_FEATURE_FLAG_CRM_SCREENER_LEAD_S_OFFENDER_CHECK'
}

export class FeatureFlagService {
  public static isEnabled(featureName: ExacareFeature): boolean {
    const overriddenFeatureFlag = this.getOverriddenValue(featureName);
    if (overriddenFeatureFlag === true || overriddenFeatureFlag === false) {
      return overriddenFeatureFlag;
    }
    return import.meta.env[featureName] === 'true';
  }

  /**
   *
   * @param featureName The feature flag to check if overridden
   * @returns boolean if the feature flag is overridden as either true|false
   * @returns null if the feature flag is not overridden
   */
  private static getOverriddenValue(
    featureName: ExacareFeature
  ): boolean | null {
    const featureFlags = localStorage.getItem(LOCAL_STORAGE_FEATURE_FLAGS_KEY);

    if (!featureFlags) {
      return null;
    }

    try {
      const parsedFeatureFlags = JSON.parse(featureFlags) as Record<
        string,
        boolean
      >;
      if (
        parsedFeatureFlags[featureName] === true ||
        parsedFeatureFlags[featureName] === false
      ) {
        return parsedFeatureFlags[featureName];
      }
      return null;
    } catch (e) {
      return null;
    }
  }
}
